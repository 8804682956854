import { captureException, setExtra, setTags } from '@sentry/react';
import ky from 'ky';

import { API } from '@/auth/config';
import { instance } from './instance';

export class ApiError extends Error {
  code: number;
  message: string;
  data: unknown;

  constructor(message: string, code: number, url: string, data: unknown) {
    super(message);
    this.message = message;
    this.code = code;
    this.data = data;
    this.name = 'ApiError';

    setTags({
      error_code: code,
      request_url: url,
    });

    setExtra('response', JSON.stringify(data));

    captureException(this);
  }
}

class NetworkError extends Error {
  constructor(message: string, url: string) {
    super(message);
    this.name = 'NetworkError';

    setTags({
      request_url: url,
    });

    captureException(this);
  }
}

class SyntaxError extends Error {
  constructor(message: string, url: string) {
    super(message);
    this.name = 'SyntaxError';

    setTags({
      request_url: url,
    });

    captureException(this);
  }
}

export const api = ky.extend({
  retry: 0,
  timeout: 60000,
  fetch: async (input, init): Promise<Response> => {
    try {
      return await fetch(input, init);
    } catch (error: any) {
      if (input instanceof Request) {
        if (!error.response) {
          throw new NetworkError(error, input.url);
        }
        throw new SyntaxError(error, input.url);
      }

      throw error;
    }
  },
  hooks: {
    beforeRequest: [
      async request => {
        const scopes = getScopes(request.url);

        if (scopes.length) {
          const token = await instance.acquireTokenSilent({ scopes });
          request.headers.set('Authorization', `Bearer ${token.accessToken}`);
        }

        return request;
      },
    ],
    beforeError: [
      async error => {
        const { response } = error;

        let errorData: null | unknown = null;

        if (response.body) {
          try {
            errorData = await response.clone().json();
          } catch (error) {
            errorData = await response.clone().text();
          }
        }

        throw new ApiError(error.message, response.status, response.url, errorData);
      },
    ],
  },
});

const getScopes = (url: string) => {
  const lowerCaseUrl = url.toLowerCase();

  switch (true) {
    case lowerCaseUrl.startsWith(API.Sim.url):
      return [API.Sim.scope];
    case lowerCaseUrl.startsWith(API.Consumption.url):
      return [API.Consumption.scope];
    case lowerCaseUrl.startsWith(API.Users.url):
      return [API.Users.scope];
    case lowerCaseUrl.startsWith(API.Publish.url):
      return [API.Publish.scope];
    case lowerCaseUrl.startsWith(API.Graph.url):
      return [API.Graph.scope];
    case lowerCaseUrl.startsWith(API.Notify.url):
      return [API.Notify.scope];
    default:
      return [];
  }
};
