import { useMsal } from '@azure/msal-react';
import { FlagProvider } from '@unleash/proxy-client-react';

import { env } from '@/utils/env';

export default function UnleashProvider({ children }: React.PropsWithChildren) {
  const { accounts } = useMsal();

  const signedInAccount = accounts.at(0);

  if (!signedInAccount) {
    console.warn('No user signed in');
    return children;
  }

  const isHosted = env.version !== undefined;

  return (
    <FlagProvider
      config={{
        url: 'https://sim-unleash.telco.intility.com/api/frontend/',
        clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
        environment: import.meta.env.VITE_ENVIRONMENT,
        appName: 'intility-sim',
        context: {
          userId: signedInAccount.username,
          properties: {
            tenantId: signedInAccount.tenantId,
          },
        },
        refreshInterval: isHosted ? 5 : 60,
      }}
    >
      {children}
    </FlagProvider>
  );
}
