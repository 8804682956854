import { MsalProvider } from '@azure/msal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryString from 'query-string';
import type { ReactElement } from 'react';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { instance, useMsalRouterIntegration } from '@/auth';
import { FullScreenLoader } from '@/components/FullscreenLoader/FullscreenLoader';
import UnleashProvider from '@/components/UnleashProvider';
import { A2HSProvider } from '@/context/A2HSContext';
import { AuthorizationProvider } from '@/context/AuthorizationContext';
import { ReactQueryProvider } from '@/lib/react-query';

type AppProviderProps = {
  children: ReactElement;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  useMsalRouterIntegration();

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <HelmetProvider>
        <MsalProvider instance={instance}>
          <UnleashProvider>
            <QueryParamProvider
              adapter={ReactRouter6Adapter}
              options={{
                searchStringToObject: queryString.parse,
                objectToSearchString: queryString.stringify,
              }}
            >
              <ReactQueryProvider>
                {import.meta.env.DEV && <ReactQueryDevtools />}
                {children}
              </ReactQueryProvider>
            </QueryParamProvider>
          </UnleashProvider>
        </MsalProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export const ProtectedAppProvider = ({ children }: AppProviderProps) => {
  return (
    <AuthorizationProvider>
      <A2HSProvider>{children}</A2HSProvider>
    </AuthorizationProvider>
  );
};
