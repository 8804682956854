import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@intility/bifrost-react';

export const IntilitySupportLink = () => {
  return (
    <a href='https://my.intility.com/chat' className='bf-link' target='_blank' rel='noreferrer'>
      Intility Support
      <Icon icon={faExternalLink} marginLeft />
    </a>
  );
};
