import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, Grid, Icon } from '@intility/bifrost-react';
import { useFlag } from '@unleash/proxy-client-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useUsers } from '@/features/user-management/api';
import { routes } from '@/routes/config';
import { FeatureFlags } from '@/types/feature-flags';
import { FullscreenMessage } from '../FullscreenMessage';
import { IntilitySupportLink } from '../IntilitySupportLink';

export const NoAccessFullscreenMsg = ({
  accessCanBeProvidedByAdmin,
}: {
  accessCanBeProvidedByAdmin?: boolean;
}) => {
  const showOrderFlowV2 = useFlag(FeatureFlags.USE_ORDER_FLOW_V2);
  const users = useUsers(accessCanBeProvidedByAdmin);
  const [expanded, setExpanded] = useState(false);

  const getAdminUsers = () => {
    const adminUsers = users.data?.items.filter(user => user.role === 'Admin');
    if (expanded) {
      return adminUsers;
    } else {
      return adminUsers?.slice(0, 3);
    }
  };

  return (
    <FullscreenMessage backgroundClassname='bg-bfc-base-2' textAlign='center'>
      <FullscreenMessage.Icon icon={faTriangleExclamation} color='var(--bfc-theme)' size={'4x'} />
      <FullscreenMessage.Header>Du har ikke tilgang til denne siden</FullscreenMessage.Header>
      <FullscreenMessage.Paragraph className='mt-bfs-8'>
        {accessCanBeProvidedByAdmin && (
          <div className='mb-bfs-12'>
            <p className='mb-bfs-12'>Kontakt en av følgende administratorer for å få tilgang:</p>
            <Grid cols={1}>
              <div className='rounded-xl border border-bfc-base-dimmed bg-bfc-base-3 p-bfs-12'>
                <Grid cols={1}>
                  {getAdminUsers()?.map(user => (
                    <div key={user.id}>
                      <Badge state='neutral'>{user.displayName}</Badge>
                    </div>
                  ))}

                  {
                    <button type='button' onClick={() => setExpanded(!expanded)}>
                      {expanded ? (
                        <p className='bf-link'>
                          <Icon icon={faAngleUp} marginRight />
                          Vis færre
                        </p>
                      ) : (
                        <p className='bf-link'>
                          <Icon icon={faAngleDown} marginRight />
                          Vis flere
                        </p>
                      )}
                    </button>
                  }
                </Grid>
              </div>
              <p>
                Eller ta kontakt med <IntilitySupportLink /> for hjelp.
              </p>
            </Grid>
          </div>
        )}
        <Link to={showOrderFlowV2 ? routes.subscriptions.user.path : routes.overview.path}>
          <Button>Tilbake til mitt abonnement</Button>
        </Link>
      </FullscreenMessage.Paragraph>
    </FullscreenMessage>
  );
};
