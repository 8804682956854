import { useQuery } from '@tanstack/react-query';

import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { QueryKey } from '@/types/react-query';
import { usersRoleSchema } from './getUsersAccessRole.schema';

const getUsersAccessRole = async () => {
  const endpoint = `${API.Sim.url}/v1/user-access-management`;
  const data = await api.get(endpoint).json();

  return usersRoleSchema.parse(data);
};

export const useUsers = (enabled = true) => {
  return useQuery({
    queryKey: [QueryKey.Users],
    queryFn: getUsersAccessRole,
    enabled,
  });
};
