export const routes = {
  landing: {
    base: {
      path: '/',
    },
    category: {
      voice: {
        path: '/mobilabonnement',
      },
      data: {
        path: '/mobiltbredband',
      },
      iot: {
        path: '/iot',
      },
    },
    contact: {
      path: '/contact',
    },
    usecase: {
      path: '/usecase/:category/:id',
    },
  },
  overview: {
    path: '/overview',
  },
  financial: {
    detailedInvoice: {
      path: '/financial/detailed-invoice',
    },
  },
  subscriptions: {
    user: {
      path: '/subscriptions/personal',
    },
    pending: {
      path: '/subscriptions/orders',
    },
    //v1
    company: {
      path: '/subscriptions/company',
    },
  },
  order: {
    basketSummary: {
      path: '/order/basket',
    },
    checkout: {
      path: '/order/checkout',
    },
    shipping: {
      path: '/order/shipping',
    },
    summary: {
      path: '/order/summary',
    },
  },
  orders: {
    path: '/orders',
  },
  //order v1
  products: {
    path: '/products',
  },
  activate: {
    path: '/activate',
  },
  callRates: {
    domesticNumberPlans: {
      path: '/domestic',
    },
    roam: {
      path: '/roam',
    },
  },
  admin: {
    userManagement: {
      path: '/admin/users',
    },
    //order v2
    products: {
      path: '/admin/products',
    },
    planAssignment: {
      path: '/admin/plan-assignment',
    },
    planAssignmentSummary: {
      path: '/admin/plan-assignment/summary',
    },
    companySubscriptions: {
      path: '/admin/subscriptions/company',
    },
  },
  recommendations: {
    path: '/recommendations',
  },
  terms: {
    path: '/terms',
  },
  iot: {
    path: '/mobilnett',
  },
  coverage: {
    path: '/coverage',
  },
};
