import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon, Message } from '@intility/bifrost-react';
import type { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { routes } from '@/routes/config';
import { useAdminOrderStore } from '@/stores/adminOrders';

export const PendingOrderMessage = (): ReactElement | null => {
  const orderStore = useAdminOrderStore();

  const currentPath = useLocation();

  if (currentPath.pathname.includes('/admin/plan-assignment') || orderStore.users.length === 0)
    return null;

  return (
    <Message state='warning'>
      <div className='flex w-full items-center justify-between'>
        <p className='bf-p my-0 mr-bfs-12'>Du har en uferdig bestilling</p>

        <div className='flex items-center gap-bfs-16'>
          <Link to={routes.admin.planAssignment.path}>
            <Button pill small state='neutral' variant='filled'>
              Til bestilling
              <Icon icon={faArrowRight} marginLeft />
            </Button>
          </Link>
          <button className='bf-link' onClick={() => orderStore.resetOrderStore()} type='button'>
            Avbryt bestilling
          </button>
        </div>
      </div>
    </Message>
  );
};
