import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Icon, Modal } from '@intility/bifrost-react';
import type { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { createContext, useState } from 'react';

interface Props {
  children: ReactNode;
}

type HandleModalType = (
  content?: ReactElement,
  header?: string,
  icon?: IconDefinition,
  iconColor?: string,
) => void;

interface ModalContextProps {
  modal?: boolean;
  handleModal: HandleModalType;
  modalContent?: ReactElement;
  modalHeader?: string;
  setModal?: Dispatch<SetStateAction<boolean>>;
  modalHeaderIcon?: IconDefinition;
  iconColor?: string;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement>();
  const [modalHeader, setModalHeader] = useState<string>('Default header');
  const [modalHeaderIcon, setModalHeaderIcon] = useState<IconDefinition>();
  const [iconColor, setIconColor] = useState<string>('');

  const handleModal = (
    content = <></>,
    header = '',
    icon: IconDefinition | undefined,
    iconColor = '',
  ) => {
    setModal(old => !old);
    setModalContent(content);
    setModalHeader(header);
    setModalHeaderIcon(icon);
    setIconColor(iconColor);
  };

  return (
    <ModalContext.Provider
      value={{ modal, modalContent, handleModal, modalHeader, modalHeaderIcon, iconColor }}
    >
      <Modal
        onRequestClose={() => setModal(false)}
        isOpen={modal}
        header={
          <div>
            {modalHeaderIcon && <Icon color={iconColor} icon={modalHeaderIcon} marginRight />}{' '}
            {modalHeader}
          </div>
        }
        className='overflow-visible'
      >
        {modal && modalContent}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
