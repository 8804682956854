import {
  faChartNetwork,
  faPhoneLaptop,
  faRouter,
  faSimCard,
} from '@fortawesome/pro-regular-svg-icons';

import type { SubscriptionCategoryType } from '../api/getBusinessSubscriptions.schema';

export enum SubscriptionTab {
  COMPANY = 'Alle abonnementer',
  USER = 'Mine abonnementer',
  ORDERS = 'Mine bestillinger',
}

export const SubscriptionIcon = {
  DATA: faPhoneLaptop,
  VOICE: faSimCard,
  IOT: faChartNetwork,
  COMLINE: faRouter,
} as const;

export const FriendlySubscriptionType: Record<SubscriptionCategoryType, string> = {
  DATA: 'Data',
  COMLINE: 'Router',
  VOICE: 'Tale',
  IOT: 'IoT',
} as const;

export const SubscriptionOrderStatus = {
  OPEN: 'OPEN',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
} as const;

export type SubscriptionOrderStatus =
  (typeof SubscriptionOrderStatus)[keyof typeof SubscriptionOrderStatus];

export const PortingStatus = {
  COMPLETED: 'COMPLETED',
  ON_HOLD: 'ON_HOLD',
  WAITING: 'WAITING',
  ORDERED: 'ORDERED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
} as const;

export type PortingStatus = (typeof PortingStatus)[keyof typeof PortingStatus];

export const ShippingMethod = {
  LETTER: 'LETTER',
  'PARCEL/COURIER': 'PARCEL/COURIER',
} as const;

export type ShippingMethod = (typeof ShippingMethod)[keyof typeof ShippingMethod];
