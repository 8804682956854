import type { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '@intility/bifrost-react';
import type { ReactNode } from 'react';

import { cn } from '@/utils/clsx';

interface FullscreenMessageProps {
  height?: string;
  width?: string;
  textAlign?: CanvasTextAlign;
  backgroundClassname?: string;
  children: ReactNode;
}

export const FullscreenMessage = ({
  height = '100%',
  width = 'calc(clamp(300px, 50%, 600px))',
  textAlign,
  backgroundClassname = '',
  children,
}: FullscreenMessageProps) => {
  return (
    <div
      className={cn(
        'flex h-full flex-col items-center justify-center rounded-xl border border-bfc-base-dimmed p-bfs-24',
        backgroundClassname,
      )}
      style={{ height: height, textAlign: textAlign }}
    >
      <div className='flex flex-col' style={{ width: width }}>
        {children}
      </div>
    </div>
  );
};

const FullscreenMessageHeader = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <h3 className={cn('bf-h3 p-0', className)}>{children}</h3>;
};

const FullscreenMessageParagraph = ({
  padding = false,
  className,
  children,
}: {
  padding?: boolean;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <p className={cn(`bf-p bfc-base-2 text-base ${padding ? '' : 'my-bfs-0 p-bfs-0'}`, className)}>
      {children}
    </p>
  );
};

const FullscreenMessageIcon = ({
  icon,
  color,
  size,
  spinningIcon,
}: {
  icon: IconProp;
  color?: string;
  size?: SizeProp;
  spinningIcon?: boolean;
}) => {
  return <Icon icon={icon} color={color} size={size} spin={spinningIcon} />;
};

const FullscreenMessageButtons = ({ children }: { children: ReactNode }) => {
  return <div className='mt-bfs-8'>{children}</div>;
};

FullscreenMessage.Header = FullscreenMessageHeader;
FullscreenMessage.Paragraph = FullscreenMessageParagraph;
FullscreenMessage.Icon = FullscreenMessageIcon;
FullscreenMessage.Buttons = FullscreenMessageButtons;
