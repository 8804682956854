import { Bifrost } from '@intility/bifrost-react';
import nbNO from '@intility/bifrost-react/locales/nb-no';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from '@/components/Errors/ErrorBoundary';
import { NoAccessFullscreenMsg } from '@/components/Errors/NoAccessFullscreenMsg';
import { MainLayout } from '@/components/Layout';
import { useIsAdmin } from '@/context/AuthorizationContext';
import Authentication from '@/lib/auth';
import { ProtectedAppProvider } from '@/providers/app';

export const ProtectedApp = () => {
  return (
    <Bifrost locale={nbNO}>
      <ErrorBoundary>
        <Authentication>
          <ProtectedAppProvider>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </ProtectedAppProvider>
        </Authentication>
      </ErrorBoundary>
    </Bifrost>
  );
};

export const AdminRoute = () => {
  const isCustomerAdmin = useIsAdmin();

  return isCustomerAdmin ? <Outlet /> : <NoAccessFullscreenMsg />;
};
