import { FloatingMessage, Nav } from '@intility/bifrost-react';
import { useFlag } from '@unleash/proxy-client-react';
import type { ReactNode } from 'react';
import { Suspense, useContext } from 'react';
import { Link } from 'react-router-dom';

import { useIsAdmin } from '@/context/AuthorizationContext';
import { DemoContext, DemoModeProvider } from '@/context/DemoContext';
import { DrawerProvider } from '@/context/DrawerContext';
import { ModalProvider } from '@/context/ModalContext';
import { TermsValidator, UserRoleValidator } from '@/features/terms-of-service/components';
import { routes } from '@/routes/config';
import { FeatureFlags } from '@/types/feature-flags';
import { FullScreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { BottomBar } from './BottomBar';
import { PendingOrderMessage } from './PendingOrderBottomBar';
import { SideNav } from './SideNav';
import { TopNav } from './TopNav';

interface NavBarProps {
  children: ReactNode;
}

const NavBar = ({ children }: NavBarProps) => {
  const showOrderFlowV2 = useFlag(FeatureFlags.USE_ORDER_FLOW_V2);
  const { isDemoMode } = useContext(DemoContext);
  const loggedInUserIsAdmin = useIsAdmin();

  const navigateToHome = loggedInUserIsAdmin
    ? routes.admin.companySubscriptions.path
    : routes.subscriptions.user.path;
  return (
    <Nav
      className='h-full'
      logo={
        <Link to={showOrderFlowV2 ? navigateToHome : routes.overview.path}>
          <Nav.Logo logo={<img src={new URL('/sim_logo.svg', import.meta.url).href} alt={''} />}>
            {`Intility SIM ${isDemoMode ? '- Presentasjonvisning' : ''}`}
          </Nav.Logo>
        </Link>
      }
      top={<TopNav />}
      side={<SideNav />}
    >
      <Suspense fallback={<FullScreenLoader />}>
        <DrawerProvider>
          {/* <div className={'hidden md:block'}>
            <UserFeedback />
          </div> */}

          {/* <NewVersionModal /> */}

          <PendingOrderMessage />
          {children}
          <BottomBar />
        </DrawerProvider>
      </Suspense>
    </Nav>
  );
};

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <FloatingMessage className='h-full'>
      <DemoModeProvider>
        <ModalProvider>
          <TermsValidator>
            <UserRoleValidator>
              <NavBar>{children}</NavBar>
            </UserRoleValidator>
          </TermsValidator>
        </ModalProvider>
      </DemoModeProvider>
    </FloatingMessage>
  );
};
