import type { RowSelectionState, Updater } from '@tanstack/react-table';
import merge from 'lodash.merge';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import type { BillingCompanyDto } from '@/api/getBillingCompanies.schema';
import type { CreateOrderV2Dto } from '@/features/order/v2/api/createOrderV2.schema';
import type { UserDto } from '@/features/order/v2/api/user.schema';
import type { DataPlanDto } from '@/features/products/api';

export type UserUniqueDataPlan = {
  user: UserDto;
  dataPlan: DataPlanDto;
  billingCompany: BillingCompanyDto;
};

interface AdminOrderStoreState {
  order: CreateOrderV2Dto | null;
  defaultDataPlan: DataPlanDto | null;
  users: UserUniqueDataPlan[];
  pendingEditRowsState: Partial<UserUniqueDataPlan>[];
  rowSelection: RowSelectionState;
  setRowSelection: (updaterOrValue: Updater<RowSelectionState>) => void;
  setDefaultDataPlan: (dataPlan: DataPlanDto) => void;
  getUserById: (userId: string) => UserUniqueDataPlan | undefined;
  addUser: (user: UserUniqueDataPlan) => void;
  addManyUsers: (users: UserUniqueDataPlan[]) => void;
  updateUser: (updateObj: Partial<UserUniqueDataPlan>) => void;
  removeUser: (orderId: string) => void;
  removeManyUsers: (orderIds: string[]) => void;
  setPendingRowEditState: (row: Partial<UserUniqueDataPlan>) => void;
  getPendingRowEditState: (userId: string) => Partial<UserUniqueDataPlan> | undefined;
  createOrder: () => void;
  resetOrderStore: () => void;
}

export const useAdminOrderStore = create<AdminOrderStoreState>()((set, get) => ({
  order: null,
  defaultDataPlan: null,
  users: [],
  pendingEditRowsState: [],
  rowSelection: {},
  setRowSelection: updaterOrValue =>
    set(state => {
      const newRowSelection =
        typeof updaterOrValue === 'function' ? updaterOrValue(state.rowSelection) : updaterOrValue;

      return {
        ...state,
        rowSelection: newRowSelection,
      };
    }),
  setDefaultDataPlan: defaultDataPlan =>
    set(() => ({
      defaultDataPlan,
    })),
  addUser: user =>
    set(state => ({
      users: [...state.users, user],
    })),
  updateUser: updateObj =>
    set(state => {
      const existingUserIndex = state.users.findIndex(
        row => row.user.userId === updateObj.user?.userId,
      );
      if (existingUserIndex > -1) {
        state.users[existingUserIndex] = merge({}, state.users[existingUserIndex], updateObj);
      }
      return { users: [...state.users] };
    }),
  getUserById: (userId: string) => get().users.find(user => user.user.userId === userId),
  setPendingRowEditState: pendingEditRowsState =>
    set(state => {
      const existingUserIndex = state.pendingEditRowsState.findIndex(
        row => row.user?.userId === pendingEditRowsState.user?.userId,
      );

      if (existingUserIndex > -1) {
        state.pendingEditRowsState[existingUserIndex] = {
          ...state.pendingEditRowsState[existingUserIndex],
          ...pendingEditRowsState,
        };
      } else {
        state.pendingEditRowsState.push({
          user: pendingEditRowsState.user,
          dataPlan: pendingEditRowsState.dataPlan,
          billingCompany: pendingEditRowsState.billingCompany,
        });
      }
      return { pendingEditRowsState: [...state.pendingEditRowsState] };
    }),

  getPendingRowEditState: userId =>
    get().pendingEditRowsState.find(pending => pending.user?.userId === userId),

  addManyUsers: users =>
    set(state => {
      const existingUserIds = state.users.map(existingUser => existingUser.user.userId);
      users = users.filter(user => !existingUserIds.includes(user.user.userId));
      const newUsers = [...state.users, ...users];

      return { users: newUsers };
    }),
  removeUser: userId =>
    set(state => ({
      users: state.users.filter(user => user.user.userId !== userId),
    })),
  removeManyUsers: userIds =>
    set(state => ({
      users: state.users.filter(user => !userIds.includes(user.user.userId)),
    })),
  createOrder: () =>
    set(state => {
      const orderId = state.order?.orderId ?? uuidv4();

      const subscriptions = state.users.map(user => ({
        dataPlan: user.dataPlan.dataPlanId,
        attributes: {
          userId: user.user.userId,
          billingCompanyId: user.billingCompany.id,
        },
      }));

      return {
        order: {
          orderId,
          description: null,
          subscriptions,
        },
      };
    }),
  resetOrderStore: () =>
    set({
      defaultDataPlan: null,
      users: [],
      pendingEditRowsState: [],
      order: null,
      rowSelection: {},
    }),
}));
