import { z } from 'zod';

import { PortingStatus } from '../types/subscription';
import { additionalSimSchema, simSchema } from './getSim.schema';

export const SubscriptionCategoryType = {
  VOICE: 'VOICE',
  DATA: 'DATA',
  IOT: 'IOT',
  COMLINE: 'COMLINE',
} as const;

export const Carrier = {
  TELIA: 'Telia',
  TELENOR: 'Telenor',
} as const;

export type Carrier = (typeof Carrier)[keyof typeof Carrier];

export const businessSubscriptionSchema = z.object({
  subscriptionId: z.string(),
  createdAt: z.string(),
  description: z.string().nullable(),
  isFavorite: z.boolean(),
  rolloverMegabyteData: z.number(),
  isTerminated: z.boolean(),
  carrier: z.nativeEnum(Carrier),
  phoneNumber: z.object({
    phoneNumberId: z.string(),
    phoneNumber: z.string(),
    countryCode: z.number(),
    originalServiceProviderId: z.string(),
    previousServiceProviderId: z.string(),
    isSecretNumber: z.boolean(),
    isPortedIn: z.boolean(),
    phoneNumberType: z.enum(['VOICE', 'DATA']),
  }),
  attributes: z.object({
    companyCode: z.string(),
    companyId: z.string(),
    companyName: z.string().nullable(),
    locationCode: z.string(),
    locationId: z.string(),
    locationName: z.string().nullable(),
    attachedUserEntraObjectId: z.string(),
    attachedUserEmail: z.string().nullable(),
    attachedUserFullName: z.string().nullable(),
    billingCompanyId: z.number(),
    billingCompanyName: z.string().nullable(),
    tenantId: z.string(),
    organizationNumber: z.string(),
  }),
  subscriptionCategoryType: z.nativeEnum(SubscriptionCategoryType),
  activeDataPlan: z.object({
    dataPlanId: z.string(),
    name: z.string(),
    fullName: z.string(),
    description: z.string().nullable(),
    carrier: z.nativeEnum(Carrier),
    includedByteDataLimit: z.number(),
    underlyingByteDataLimit: z.number().nullable(),
    customerMonthlyPrice: z.number(),
    overUsageGigabytePrice: z.number(),
    setupPrice: z.number(),
    isDisabled: z.boolean(),
    isFavorite: z.boolean(),
    subscriptionType: z.object({
      code: z.string(),
      name: z.string(),
    }),
    subscriptionCategoryType: z.enum(['COMLINE', 'DATA', 'IOT', 'VOICE']),
    apnNetworkConfig: z.string(),
    tenantId: z.string().nullable(),
    currency: z.string(),
  }),
  primarySim: simSchema,
  additionalSims: z.array(additionalSimSchema),
  dataUsage: z
    .object({
      bytes: z.string(),
      displayValue: z.string(),
      gigaBytes: z.number(),
      megaBytes: z.number(),
    })
    .nullable(),
  portingStatus: z.nativeEnum(PortingStatus).nullable(),
});

export const activeSubscriptionSchema = businessSubscriptionSchema
  .pick({
    subscriptionId: true,
    carrier: true,
    subscriptionCategoryType: true,
  })
  .extend({
    phoneNumber: z.string(),
    dataPlanName: z.string(),
    dataPlanId: z.string(),
  });

export const businessSubscriptionsSchema = z.object({
  totalItems: z.number(),
  items: z.array(businessSubscriptionSchema),
});

export type BusinessSubscriptionDto = z.infer<typeof businessSubscriptionSchema>;
export type BusinessSubscriptionsDto = z.infer<typeof businessSubscriptionsSchema>;
export type SubscriptionCategoryType =
  (typeof SubscriptionCategoryType)[keyof typeof SubscriptionCategoryType];

export type SubscriptionSimDto = z.infer<typeof simSchema>;

export type PhoneNumberDto = BusinessSubscriptionDto['phoneNumber'];
